import { useScroll } from "@react-three/drei";
import MediumHold2 from "../Holds/MediumHold2";
import { useEffect, useState } from "react";

export default function Project2(props: {
  zeroed_position: [number, number, number];
  color: string;
}) {
  const [x, y, z] = props.zeroed_position;
  const [hovered, setHovered] = useState(false);
  const holdColor = props.color;
  useEffect(() => {
    document.body.style.cursor = hovered ? "grab" : "auto";
  }, [hovered]);

  return (
    <>
      <mesh
        onClick={() => {
          window.open("https://github.com/wzhang1123/MVPPredictor");
        }}
        rotation={[Math.PI / 2, 0, 0]}
        position={[x + 0, y + 0, z + 0]}
        onPointerOver={() => setHovered(true)}
        onPointerOut={() => setHovered(false)}
      >
        <MediumHold2 color={holdColor} />
      </mesh>
    </>
  );
}
