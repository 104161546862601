import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { Material, DoubleSide } from "three";
import * as THREE from "three";
import { MeshStandardMaterial } from "three";

export default function Jug1(props) {
  const { nodes, materials } = useGLTF("/CH4_S.gltf");
  console.log(materials.Mat_1);
  console.log("color", props.color);
  // Make the material double-sided
  materials.Mat_1.side = DoubleSide;
  materials.Mat_1.color = props.color;
  const material = new MeshStandardMaterial({
    color: props.color,
    side: DoubleSide,
  });
  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.CH4_S.geometry}
        material={material}
        scale={[0.1, 0.1, 0.1]}
      />
    </group>
  );
}

useGLTF.preload("/CH4_S.gltf");
