import "./styles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { contacts } from "../../../Data";

export default function Contact() {
  const c = new Map();

  for (const contact of contacts) {
    c.set(contact["source"], contact["contact"]);
  }
  return (
    <>
      <div className="max-width-800">
        <div className="initial-fade-in inter d-flex flex-column">
          <div className="large-row-spacer" />

          <div className="d-flex flexrow justify-content-between max-width-300">
            {/* <h6 className="semi-bold white-text">CONTACT</h6> */}
            <div>
              <img
                src="/github-mark-white.png"
                alt=""
                height={36}
                width={36}
                onClick={() => window.open(c.get("GitHub"))}
                style={{ cursor: "pointer" }}
              />
            </div>
            <div>
              <img
                src="/In-White-96.png"
                alt=""
                height={36}
                width={36}
                onClick={() => window.open(c.get("LinkedIn"))}
                style={{ cursor: "pointer" }}
              />
            </div>
            <div>
              <img
                src="/Instagram_Glyph_White.png"
                alt=""
                height={36}
                width={36}
                onClick={() => window.open(c.get("Instagram"))}
                style={{ cursor: "pointer" }}
              />
            </div>
            <div>
              <a href="mailto:wzhang12321@gmail.com?subject=Hey Will!">
                <img
                  src="/envelope.png"
                  alt=""
                  height={36}
                  width={36}
                  // style={{ cursor: "pointer" }}
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
