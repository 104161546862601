import { useEffect, useState } from "react";
import SmallHold1 from "../Holds/SmallHold1";

export default function Project3(props: {
  zeroed_position: [number, number, number];
  color: string;
}) {
  const [x, y, z] = props.zeroed_position;
  const holdColor = props.color;

  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    document.body.style.cursor = hovered ? "grab" : "auto";
  }, [hovered]);
  return (
    <>
      <mesh
        onClick={() => {
          window.open("https://www.willzhang.dev");
        }}
        rotation={[Math.PI / 2, 0, 0]}
        position={[x + 0, y + 0, z + 0]}
        onPointerOver={() => setHovered(true)}
        onPointerOut={() => setHovered(false)}
      >
        <SmallHold1 color={holdColor} />
      </mesh>
    </>
  );
}
