import { Canvas } from "@react-three/fiber";
import CameraController from "./cameraController";
import { JsxElement } from "typescript";
import { OrbitControls } from "@react-three/drei";

export default function CanvasViewer(props: any) {
  const child = props.child;
  const cameraRef = props.cameraRef;
  const baseScrollY = props.baseScrollY;

  //   const child = element;【‘
  return (
    <div>
      <Canvas camera={cameraRef.current}>
        {/* <OrbitControls /> */}
        {/* <ambientLight intensity={0.9} />
        <directionalLight intensity={0.5} position={[50, 50, -50]} /> */}
        <ambientLight intensity={0.9} />
        <directionalLight intensity={0.3} position={[50, 50, -50]} />
        {child}
        <CameraController cameraRef={cameraRef} baseScrollY={baseScrollY} />
      </Canvas>
    </div>
  );
}
