import "./styles.css";
import { experiences } from "../../../Data";
import { PiArrowUpRightBold } from "react-icons/pi";
import TravelersHold from "../../Scene/Experiences/travelers_hold";
import NASAHold from "../../Scene/Experiences/nasa_hold";
import CanvasViewer from "../CanvasViewer";
import { useRef } from "react";
import { PerspectiveCamera } from "three";

export default function Experience() {
  const holds = new Map();
  holds.set(
    "Travelers Insurance",
    <TravelersHold zeroed_position={[-20, 0, 0]} color="MediumSeaGreen" />
  );
  holds.set(
    "NASA, Langley Research Center",
    // <NASAHold zeroed_position={[0, 0, 0]} color="#217D63" />
    <NASAHold zeroed_position={[25, 0, 0]} color="tomato" />
  );

  const ys = new Map();
  ys.set("Travelers Insurance", 410);
  ys.set("NASA, Langley Research Center", 670);

  const camera1Ref = useRef(
    new PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000)
  );
  camera1Ref.current.position.set(0, 0, 25);

  return (
    <>
      <div className="max-width-800">
        <div className="initial-fade-in inter d-flex flex-column">
          <h6 className="semi-bold white-text">EXPERIENCE</h6>
          <div className="large-row-spacer" />

          {experiences.map((experience) => (
            <div key={experience.id} className="d-flex flex-column">
              <div className="d-flex switch-to-vert">
                <div></div>
                <div className="width-300" style={{ flex: "0 0 auto" }}>
                  <small className="opacity-75">
                    {experience["start-date"]} - {experience["end-date"]}
                  </small>
                </div>
                <div
                  className="d-flex flex-column align-items-start"
                  style={{ flex: "1 1 auto" }}
                >
                  <div className="flex">
                    <p
                      className="slightly-bold hover"
                      onClick={() => {
                        window.open(experience["company-site"]);
                      }}
                    >
                      {experience["position"]} - {experience["company"]}{" "}
                      <PiArrowUpRightBold />
                    </p>
                  </div>
                  <div>
                    <small className="opacity-75">
                      {experience.description}{" "}
                    </small>
                  </div>
                </div>
              </div>
              <div>
                <CanvasViewer
                  child={holds.get(experience.company)}
                  cameraRef={camera1Ref}
                  baseScrollY={ys.get(experience.company)}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
