import "./styles.css";
import { projects } from "../../../Data";
import { PiArrowUpRightBold } from "react-icons/pi";
import CanvasViewer from "../CanvasViewer";
import { useRef } from "react";
import { PerspectiveCamera } from "three";
import Project1 from "../../Scene/Projects/project1";
import Project2 from "../../Scene/Projects/project2";
import Project3 from "../../Scene/Projects/project3";

export default function Projects() {
  const holds = new Map();
  holds.set("1", <Project1 zeroed_position={[20, 0, 0]} color="#ee69b1" />);
  holds.set("2", <Project2 zeroed_position={[-7, 0, 0]} color="yellow" />);
  holds.set("3", <Project3 zeroed_position={[3, 0, 0]} color="deepskyblue" />);

  const ys = new Map();
  ys.set("1", 1130);
  ys.set("2", 1330);
  ys.set("3", 1500);

  const camera1Ref = useRef(
    new PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000)
  );
  camera1Ref.current.position.set(0, 0, 25);
  const camera2Ref = useRef(
    new PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000)
  );
  camera1Ref.current.position.set(0, 0, 25);
  const camera3Ref = useRef(
    new PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000)
  );
  camera1Ref.current.position.set(0, 0, 25);

  const cameras = new Map();
  cameras.set("1", camera1Ref);
  cameras.set("2", camera2Ref);
  cameras.set("3", camera3Ref);

  return (
    <>
      <div className="max-width-800">
        <div className="initial-fade-in inter d-flex flex-column">
          <h6 className="semi-bold white-text">PROJECTS</h6>
          <div className="large-row-spacer" />
          {projects.map((project) => (
            <div key={project.id} className="d-flex flex-column">
              <div className="d-flex flex-row">
                <div
                  className="d-flex flex-column align-items-start"
                  style={{ flex: "1 1 auto" }}
                >
                  <div className="flex">
                    <p
                      className="slightly-bold hover"
                      onClick={() => {
                        window.open(project["project-link"]);
                      }}
                    >
                      {project.title} <PiArrowUpRightBold />
                    </p>
                  </div>
                  <div>
                    <small className="opacity-75">{project.description} </small>
                  </div>
                </div>
              </div>
              <div>
                <CanvasViewer
                  child={holds.get(project.id)}
                  cameraRef={cameras.get(project.id)}
                  baseScrollY={ys.get(project.id)}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
