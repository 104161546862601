import { useEffect, useRef } from "react";
import { useFrame } from "@react-three/fiber";

const CameraController = ({ cameraRef, baseScrollY }) => {
  const camera = cameraRef.current;
  const element = document.getElementById("root");
  const baseY = baseScrollY;
  //   const baseScrollY;

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = element.scrollTop; // Get current scroll position
      console.log("scrolly", scrollY);
      //   const maxScroll = document.body.scrollHeight - window.innerHeight;
      //   const scrollFraction = scrollY / maxScroll;
      const radiansPerPixel = (scrollY - baseY) / 25000;
      const radius = 25;
      const angle = radiansPerPixel * radius;
      //   const newCameraY = 0 - scrollY / 50; // Adjust this value to suit your needs
      const newZ = radius * Math.cos(angle);
      const newY = radius * -1 * Math.sin(angle);
      //   camera.position.y = newCameraY;
      //   camera.position.y = 0;

      camera.position.y = newY;
      camera.position.z = newZ;

      console.log(camera.position);
      camera.lookAt(0, 0, 0);
    };

    element.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [camera]);

  useFrame(() => {
    camera.updateProjectionMatrix();
  });

  return null;
};

export default CameraController;
