import { Route, Routes } from "react-router";
import { Link } from "react-router-dom";
import Scene from "../Scene";
import { Suspense, useEffect, useRef } from "react";
import Content from "./Content";
import "../../App.css";
import { Canvas, useFrame, useThree } from "@react-three/fiber";
import { Scroll, ScrollControls } from "@react-three/drei";
import Project2 from "../Scene/Projects/project2";
import Intro from "./Content/intro";
import About from "./Content/about";
import Experience from "./Content/experience";
import Projects from "./Content/projects";
import Contact from "./Content/contact";
import { Camera, PerspectiveCamera } from "three";
import CameraController from "./cameraController";
import CanvasViewer from "./CanvasViewer";
import TravelersHold from "../Scene/Experiences/travelers_hold";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Content/styles.css";
// import Navigation from "./Navigation";

export default function Home() {
  return (
    <div className="container-fluid justify-content-center">
      {/* <Suspense fallback={<div>Loading...</div>}> */}
      <div className="row flex-wrap justify-content-center my-5">
        <div
          className="col-lg-6"
          style={{
            display: "flex",
            justifyContent: "center",
            // position: "fixed",
            // alignItems: "center",
          }}
        >
          <div className="max-width-500">
            <Intro />

            <Contact />
          </div>
        </div>

        <div className="col-lg-6">
          <div className="hide-on-big"></div>
          <About />
          <Experience />
          <Projects />
        </div>
      </div>
      {/* </Suspense> */}
    </div>
  );
}
